import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CalendarView from "./CalendarView";

const FestivalCalendarView = () => {
  const { festivalId } = useParams();
  const [screenings, setScreenings] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [loading, setLoading] = useState(true);
  const [festival, setFestival] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Fetch festival details for the date range
        const festivalResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/festivals/${festivalId}`
        );
        setFestival(festivalResponse.data);

        // Generate date range from festival start to end date
        if (festivalResponse.data.start_date && festivalResponse.data.end_date) {
          const start = new Date(festivalResponse.data.start_date);
          const end = new Date(festivalResponse.data.end_date);
          const range = [];
          
          for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            range.push(dt.toISOString().split('T')[0]);
          }
          setDateRange(range);
        }

        // Fetch screenings for this festival
        const screeningsResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/screenings/festival/${festivalId}`
        );

        // Format screenings data for the calendar
        const formattedScreenings = screeningsResponse.data.map(screening => ({
          id: screening.screening_id.toString(),
          title: screening.film_title,
          start: screening.start_time,
          end: screening.end_time,
          venue: screening.venue_name
        }));

        setScreenings(formattedScreenings);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching calendar data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [festivalId]);

  if (loading) {
    return <div>Loading calendar view...</div>;
  }

  // Simple event rendering
  const renderEvent = (event) => (
    <div
      key={event.id}
      style={{
        backgroundColor: "#ffffff",
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "8px",
        fontSize: "12px",
        cursor: "pointer",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div>{event.title}</div>
      <div style={{ fontSize: "10px", color: "#666" }}>{event.venue}</div>
    </div>
  );

  return (
    <div>
      <h2>{festival?.name} Calendar</h2>
      <CalendarView
        dateRange={dateRange}
        events={screenings}
        renderEvent={renderEvent}
      />
    </div>
  );
};

export default FestivalCalendarView;