import React, { useState, useEffect } from "react";
import axios from "axios"; // Assuming you're using Axios
import { useAuth } from "../../contexts/AuthContext"; // Import useAuth hook
import moment from "moment-timezone";

import {
  UnavailabilityContainer,
  DayContainer,
  UnavailabilityRow,
  IconButton,
  SaveIcon,
  CancelIcon,
  AvailableAllDay,
  UnavailabilityDate,
  DeleteIcon,
  BusyIcon,
  HeaderContainer,
  ScrollableDatesContainer,
  AddButton,
  UnavailabilityRowContent,
  TempUnavailabilityRowContent,
  ErrorMessage,
  AllDayButton,
  ButtonContainer,
} from "./FestivalUnavailability.styled";

const DATE_FORMAT = "MMMM DD";
const TIME_FORMAT = "HH:mm";

// Utility function to check for overlapping time windows
const isTimeWindowOverlapping = (newWindow, existingWindows) => {
  const newStart = moment.utc(newWindow.start_datetime);
  const newEnd = moment.utc(newWindow.end_datetime);

  return existingWindows.some((window) => {
    const existingStart = moment.utc(window.start_datetime);
    const existingEnd = moment.utc(window.end_datetime);

    return (
      newStart.isBetween(existingStart, existingEnd, null, "[)") ||
      newEnd.isBetween(existingStart, existingEnd, null, "(]") ||
      existingStart.isBetween(newStart, newEnd, null, "[)") ||
      existingEnd.isBetween(newStart, newEnd, null, "(]") ||
      newStart.isSame(existingStart) ||
      newEnd.isSame(existingEnd) ||
      (newStart.isSameOrBefore(existingStart) &&
        newEnd.isSameOrAfter(existingEnd))
    );
  });
};




const createAllDayUnavailability = (day, timezone) => {
  const startDateTime = moment.tz(`${day}T00:00:00`, timezone).toISOString();
  const endDateTime = moment.tz(`${day}T23:59:59`, timezone).toISOString();
  return { start_datetime: startDateTime, end_datetime: endDateTime };
};

// Utility function to generate time options in 15-minute increments
const generateTimeOptions = () => {
  const options = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 15) {
      const hour = i.toString().padStart(2, "0");
      const minute = j.toString().padStart(2, "0");
      options.push(`${hour}:${minute}`);
    }
  }
  return options;
};

const timeOptions = generateTimeOptions();

const isValidTimeWindow = (start_datetime, end_datetime, timezone) => {
  // return moment.utc(end_datetime).isAfter(moment.utc(start_datetime));
  return moment
    .tz(end_datetime, timezone)
    .isAfter(moment.tz(start_datetime, timezone));
};

const FestivalUnavailability = ({
  festivalId,
  festivalStartDate,
  festivalEndDate,
  timezone,
}) => {
  const { userId, token } = useAuth(); // Use the userId and token from the AuthContext
  const [unavailability, setUnavailability] = useState([]);
  const [tempUnavailability, setTempUnavailability] = useState([]);
  const [isTimeWindowValid, setIsTimeWindowValid] = useState(true);

  const [timeWindowError, setTimeWindowError] = useState("");

  const generateDates = (startDate, endDate, timezone) => {
    let dates = [];
    let currentDate = moment.tz(startDate, timezone).startOf("day");
    const endMoment = moment.tz(endDate, timezone).startOf("day");

    while (currentDate <= endMoment) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      // Increment by one day, taking into account the timezone and any DST changes
      currentDate.add(1, "days");
    }

    return dates;
  };

  const allFestivalDates = generateDates(
    festivalStartDate,
    festivalEndDate,
    timezone,
  );

  const addTempUnavailability = (day, timezone) => {
    const startDateTime = moment
      .tz(`${day}T12:00:00`, timezone)

      .toISOString();
    const endDateTime = moment
      .tz(`${day}T12:15:00`, timezone)

      .toISOString();
    // console.log(endDateTime);

    setTempUnavailability([
      ...tempUnavailability,
      { start_datetime: startDateTime, end_datetime: endDateTime },
    ]);
  };

  // Fetch unavailability windows from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userId && token) {
          // Check if userId and token exist
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/userfestivalschedule/${userId}/${festivalId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Include the token here
              },
            },
          );
          setUnavailability(response.data);
        }
      } catch (error) {
        console.error("Could not fetch unavailability windows:", error);
      }
    };
    fetchData();
  }, [userId, festivalId, token]); // Add userId and token to the dependency list

  const updateTime = (tempWindow, field, newTime, currentDay) => {
    setTimeWindowError(""); // Clear error when updating time
    setTempUnavailability((currentTempUnavailability) => {
      return currentTempUnavailability.map((window) => {
        if (window === tempWindow) {
          // Use the passed `currentDay` to get the date part for the new datetime
          // console.log(window.end_datetime, window.start_datetime);
          const datePart = moment
            .tz(window.end_datetime, timezone)
            .format("YYYY-MM-DD");
          // console.log(datePart);
          const updatedTime = moment
            .tz(`${datePart}T${newTime}`, timezone)

            .toISOString();
          // console.log(updatedTime);
          return { ...window, [field]: updatedTime };
        }
        return window;
      });
    });
  };

  // Function to save a temporary unavailability window
  const saveTempUnavailability = async (windowToSave) => {
    setTimeWindowError("");
    // Validate time window
    if (
      !isValidTimeWindow(
        windowToSave.start_datetime,
        windowToSave.end_datetime,
        timezone,
      )
    ) {
      setTimeWindowError("End time must be later than start time.");
      return;
    }

    // Check for overlapping windows
    if (isTimeWindowOverlapping(windowToSave, unavailability)) {
      // console.log(windowToSave, unavailability);
      setTimeWindowError("This time window overlaps with an existing one.");
      return;
    }

    const startDateTimeUTC = windowToSave.start_datetime;
    const endDateTimeUTC = windowToSave.end_datetime;
    // console.log("start date = ", startDateTimeUTC);
    // console.log("endDate ", endDateTimeUTC);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/userfestivalschedule`,
        {
          user_id: userId,
          festivalId: festivalId,
          start_datetime: startDateTimeUTC,
          end_datetime: endDateTimeUTC,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setUnavailability((prevUnavailability) => [
        ...prevUnavailability,
        response.data,
      ]);

      // Clear the tempUnavailability state
      setTempUnavailability([]);
    } catch (error) {
      console.error("Could not save unavailability window:", error);
      setTimeWindowError("Failed to save the unavailability window.");
    }
  };

  // Function to delete an unavailability window
  const deleteUnavailability = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/userfestivalschedule/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add this line
          },
        },
      );
      setUnavailability(unavailability.filter((window) => window.id !== id));
    } catch (error) {
      console.error("Could not delete unavailability window:", error);
    }
  };

  const cancelTempUnavailability = (windowToCancel) => {
    setTempUnavailability((currentTempUnavailability) =>
      currentTempUnavailability.filter((window) => window !== windowToCancel),
    );
  };

  const [isCurrentWindowValid, setIsCurrentWindowValid] = useState(true);
  const [currentWindowError, setCurrentWindowError] = useState("");

  useEffect(() => {
    validateCurrentWindow();
  }, [tempUnavailability, unavailability]);

  const validateCurrentWindow = () => {
    if (tempUnavailability.length === 0) {
      setIsCurrentWindowValid(true);
      setCurrentWindowError("");
      return;
    }

    const lastWindow = tempUnavailability[tempUnavailability.length - 1];

    // Check if the time window is valid
    if (
      !isValidTimeWindow(
        lastWindow.start_datetime,
        lastWindow.end_datetime,
        timezone,
      )
    ) {
      setIsCurrentWindowValid(false);
      setCurrentWindowError("End time must be later than start time.");
      return;
    }

    // Check for overlapping windows with existing unavailability
    if (isTimeWindowOverlapping(lastWindow, unavailability)) {
      setIsCurrentWindowValid(false);
      setCurrentWindowError("This time window overlaps with an existing one.");
      return;
    }

    // Check for overlapping windows within the tempUnavailability itself
    const tempOverlap = tempUnavailability.some((window, index) => {
      if (index === tempUnavailability.length - 1) return false; // Skip the last window (it's the one we're checking)
      return isTimeWindowOverlapping(lastWindow, [window]);
    });

    if (tempOverlap) {
      setIsCurrentWindowValid(false);
      setCurrentWindowError(
        "This time window overlaps with another temporary window.",
      );
      return;
    }

    setIsCurrentWindowValid(true);
    setCurrentWindowError("");
  };

  const hasTempUnavailabilityForDay = (day) => {
    return tempUnavailability.some(
      (window) =>
        moment.tz(window.start_datetime, timezone).format("YYYY-MM-DD") === day,
    );
  };

  const handleAllDayClick = async (day) => {
    const allDayWindow = createAllDayUnavailability(day, timezone);
    
    // Check for overlapping windows
    if (isTimeWindowOverlapping(allDayWindow, unavailability)) {
      setTimeWindowError("This time window overlaps with existing unavailability periods.");
      return;
    }
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/userfestivalschedule`,
        {
          user_id: userId,
          festivalId: festivalId,
          start_datetime: allDayWindow.start_datetime,
          end_datetime: allDayWindow.end_datetime,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUnavailability(prevUnavailability => [...prevUnavailability, response.data]);
    } catch (error) {
      console.error("Could not save all-day unavailability:", error);
      setTimeWindowError("Failed to save the all-day unavailability.");
    }
  };

  return (
    <UnavailabilityContainer>
      <HeaderContainer>
        When will you be busy? We won’t schedule any screenings during your busy
        times.
      </HeaderContainer>
      <ScrollableDatesContainer>
        {allFestivalDates.map((day) => (
          <DayContainer key={day}>
            <UnavailabilityDate>
              {moment.tz(day, timezone).format("MMMM D")}
            </UnavailabilityDate>
            {unavailability
              .filter(
                (window) =>
                  moment
                    .tz(window.start_datetime, timezone)
                    .format("YYYY-MM-DD") === day,
              )
              .map((window, index) => (
                <UnavailabilityRow key={index}>
                  <UnavailabilityRowContent>
                    <BusyIcon />
                    {moment
                      .tz(window.start_datetime, timezone)
                      .format(`${TIME_FORMAT}`)}
                    {" - "}
                    {moment
                      .tz(window.end_datetime, timezone)
                      .format(TIME_FORMAT)}
                    <IconButton onClick={() => deleteUnavailability(window.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </UnavailabilityRowContent>
                </UnavailabilityRow>
              ))}
            {tempUnavailability
              .filter(
                (window) =>
                  moment
                    .tz(window.start_datetime, timezone)
                    .format("YYYY-MM-DD") === day,
              )
              .map((window, index) => (
                <UnavailabilityRow key={index}>
                  <TempUnavailabilityRowContent>
                    {/* Time dropdowns */}
                    <IconButton
                      onClick={() => cancelTempUnavailability(window)}
                    >
                      <CancelIcon />
                    </IconButton>
                    <select
                      value={moment
                        .tz(window.start_datetime, timezone)
                        .format("HH:mm")}
                      onChange={(e) =>
                        updateTime(
                          window,
                          "start_datetime",
                          e.target.value,
                          day,
                        )
                      }
                    >
                      {timeOptions.map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                    -
                    <select
                      value={moment
                        .tz(window.end_datetime, timezone)
                        .format("HH:mm")}
                      onChange={(e) =>
                        updateTime(window, "end_datetime", e.target.value)
                      }
                    >
                      {timeOptions.map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                    <IconButton
                      onClick={() => saveTempUnavailability(window)}
                      disabled={!isCurrentWindowValid}
                    >
                      <SaveIcon />
                    </IconButton>
                  </TempUnavailabilityRowContent>
                  {currentWindowError && (
                    <ErrorMessage>{currentWindowError}</ErrorMessage>
                  )}
                </UnavailabilityRow>
              ))}
            {/* Check if no unavailability and tempUnavailability for the current day */}
            {unavailability.filter(
              (window) =>
                moment
                  .tz(window.start_datetime, timezone)
                  .format("YYYY-MM-DD") === day,
            ).length === 0 &&
            tempUnavailability.filter(
              (window) =>
                moment
                  .tz(window.start_datetime, timezone)
                  .format("YYYY-MM-DD") === day,
            ).length === 0 ? (
              <AvailableAllDay>(Available All Day)</AvailableAllDay>
            ) : null}
            <ButtonContainer>
              <AddButton
                onClick={() => addTempUnavailability(day, timezone)}
                disabled={hasTempUnavailabilityForDay(day)}
              >
                Add Busy Time
              </AddButton>
              <AllDayButton
                onClick={() => handleAllDayClick(day)}
                disabled={
                  hasTempUnavailabilityForDay(day) ||
                  unavailability.some(
                    (window) =>
                      moment
                        .tz(window.start_datetime, timezone)
                        .format("YYYY-MM-DD") === day,
                  )
                }
              >
                Busy All Day
              </AllDayButton>
            </ButtonContainer>
          </DayContainer>
        ))}
      </ScrollableDatesContainer>
    </UnavailabilityContainer>
  );
};

export default FestivalUnavailability;
