import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import axios from "axios";
import {
  CalendarContainer,
  NavigationHeader,
  NavButton,
  CurrentDate,
  ViewToggle,
  CalendarGrid,
  ScreeningBlock,
  DayLabel,
  TimeColumn,
  TimeMarker,
  DayColumn,
  FilmTitle,
  ScreeningInfo,
} from "./CalendarView.styled";

const CalendarView = ({ mode = "per-venue", filmId = null }) => {
  const { festivalId } = useParams();
  const [screenings, setScreenings] = useState([]);
  const [venues, setVenues] = useState([]);
  const [currentDate, setCurrentDate] = useState(null);
  const [festival, setFestival] = useState(null);
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState(mode);
  const [filmTitle, setFilmTitle] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const festivalResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/festivals/${festivalId}`,
        );
        setFestival(festivalResponse.data);

        if (!currentDate) {
          setCurrentDate(new Date(festivalResponse.data.start_date));
        }

        const screeningsResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/screenings/festival/${festivalId}`,
        );

        let screeningsData = screeningsResponse.data;

        if (filmId) {
          screeningsData = screeningsData.filter(
            (screening) => screening.film_id === Number(filmId),
          );
          // Set film title from the first screening if available
          if (screeningsData.length > 0) {
            setFilmTitle(screeningsData[0].film_title);
          }
        }

        const uniqueVenues = [
          ...new Set(screeningsData.map((s) => s.venue_name)),
        ].sort();
        setVenues(uniqueVenues);

        setScreenings(screeningsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching calendar data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [festivalId, currentDate, filmId]);

  if (loading || !currentDate) {
    return <div>Loading...</div>;
  }

  return (
    <CalendarContainer>
      <NavigationHeader>
        {viewMode === "per-venue" ? (
          <>
            <NavButton
              onClick={() =>
                setCurrentDate(
                  new Date(currentDate.setDate(currentDate.getDate() - 1)),
                )
              }
              disabled={currentDate <= new Date(festival.start_date)}
            >
              Previous Day
            </NavButton>

            <CurrentDate>
              {currentDate.toLocaleDateString("en-US", {
                weekday: "long",
                month: "long",
                day: "numeric",
              })}
            </CurrentDate>

            <NavButton
              onClick={() =>
                setCurrentDate(
                  new Date(currentDate.setDate(currentDate.getDate() + 1)),
                )
              }
              disabled={currentDate >= new Date(festival.end_date)}
            >
              Next Day
            </NavButton>
          </>
        ) : (
          <CurrentDate>Calendar for {filmTitle} Screenings</CurrentDate>
        )}

        {/* <ViewToggle
          onClick={() =>
            setViewMode(viewMode === "per-venue" ? "per-day" : "per-venue")
          }
        >
          {viewMode === "per-venue"
            ? "Switch to Per-Day View"
            : "Switch to Per-Venue View"}
        </ViewToggle> */}
      </NavigationHeader>

      {viewMode === "per-venue" ? (
        <VenueView
          screenings={screenings}
          currentDate={currentDate}
          venues={venues}
        />
      ) : (
        <DayView screenings={screenings} festival={festival} />
      )}
    </CalendarContainer>
  );
};

const VenueView = ({ screenings, currentDate, venues }) => {
  const currentScreenings = screenings.reduce((acc, screening) => {
    const screeningDate = new Date(screening.start_time);
    if (screeningDate.toDateString() === currentDate.toDateString()) {
      if (!acc[screening.venue_name]) {
        acc[screening.venue_name] = [];
      }
      acc[screening.venue_name].push(screening);
    }
    return acc;
  }, {});

  return (
    <div>
      {venues.map((venue) => (
        <div key={venue}>
          <h3>{venue}</h3>
          {currentScreenings[venue] ? (
            currentScreenings[venue].map((screening) => (
              <p key={screening.screening_id}>{screening.film_title}</p>
            ))
          ) : (
            <p>No screenings</p>
          )}
        </div>
      ))}
    </div>
  );
};

const DayView = ({ screenings, festival }) => {
  if (!festival) return null;

  const startDate = moment(festival.start_date).startOf("day");
  const endDate = moment(festival.end_date).startOf("day");
  const numDays = endDate.diff(startDate, "days") + 1;

  const DISPLAY_START_HOUR = 8;
  const DISPLAY_END_HOUR = 26;
  const DISPLAY_HOURS = DISPLAY_END_HOUR - DISPLAY_START_HOUR;

  const formatScreeningTime = (start, end) => {
    const startHour = start.hours();
    const endHour = end.hours();
    const startMin = start.minutes();
    const endMin = end.minutes();
    const crossesNoon = startHour < 12 && endHour >= 12;

    const getAmPm = (hour) => (hour >= 12 ? "pm" : "am");

    // If duration is exactly on the hour
    if (startMin === 0 && endMin === 0) {
      return `${startHour % 12 || 12}${
        crossesNoon ? getAmPm(startHour) : ""
      } – ${endHour % 12 || 12}${getAmPm(endHour)}`;
    }

    // If start is on hour but end isn't
    if (startMin === 0) {
      return `${startHour % 12 || 12}${
        crossesNoon ? getAmPm(startHour) : ""
      } – ${endHour % 12 || 12}:${endMin.toString().padStart(2, "0")}${getAmPm(
        endHour,
      )}`;
    }

    // If neither is on hour
    return `${startHour % 12 || 12}:${startMin.toString().padStart(2, "0")}${
      crossesNoon ? getAmPm(startHour) : ""
    } – ${endHour % 12 || 12}:${endMin.toString().padStart(2, "0")}${getAmPm(
      endHour,
    )}`;
  };

  const calculatePosition = (mTime) => {
    let hours = mTime.hours();
    const minutes = mTime.minutes();

    if (hours < DISPLAY_START_HOUR) {
      hours += 24;
    }

    const totalMinutes = (hours - DISPLAY_START_HOUR) * 60 + minutes;
    return (totalMinutes * 100) / (DISPLAY_HOURS * 60);
  };

  return (
    <CalendarGrid numDays={numDays}>
      <DayLabel style={{ gridColumn: 1, gridRow: 1 }} />
      {/* Day labels */}
      {Array.from({ length: numDays }, (_, i) => (
        <DayLabel key={i} style={{ gridColumn: i + 2, gridRow: 1 }}>
          <span className="day-name">
            {moment(startDate).add(i, "days").format("ddd")}
          </span>
          <span className="date">
            {moment(startDate).add(i, "days").format("D")}
          </span>
        </DayLabel>
      ))}

      {/* Time column */}
      <TimeColumn>
        {Array.from({ length: DISPLAY_HOURS + 1 }, (_, i) => {
          const hour = DISPLAY_START_HOUR + i;
          const displayHour = hour >= 24 ? hour - 24 : hour;
          const amPm = hour >= 24 ? "AM" : hour >= 12 ? "PM" : "AM";
          const display12Hour = displayHour % 12 || 12;

          return (
            <TimeMarker
              key={i}
              style={{
                top: `${(i * 100) / DISPLAY_HOURS}%`,
              }}
            >
              {`${display12Hour}${amPm}`}
            </TimeMarker>
          );
        })}
      </TimeColumn>

      {/* Day columns with screenings */}
      {Array.from({ length: numDays }, (_, dayIndex) => {
        const currentDayScreenings = screenings.filter((screening) => {
          const screeningDate = moment
            .tz(screening.start_time, screening.timezone)
            .startOf("day");
          const columnDate = moment(startDate)
            .tz(screening.timezone)
            .add(dayIndex, "days")
            .startOf("day");

          return (
            screeningDate.isSame(columnDate) ||
            (screeningDate.isSame(columnDate.clone().add(1, "days")) &&
              moment.tz(screening.start_time, screening.timezone).hours() < 2)
          );
        });

        return (
          <DayColumn
            key={dayIndex}
            style={{ gridColumn: dayIndex + 2, gridRow: 2 }}
          >
            {currentDayScreenings.map((screening) => {
              const screeningStart = moment.tz(
                screening.start_time,
                screening.timezone,
              );
              const screeningEnd = moment.tz(
                screening.end_time,
                screening.timezone,
              );
              const startPercent = calculatePosition(screeningStart);
              const endPercent = calculatePosition(screeningEnd);
              const height = endPercent - startPercent;

              return (
                <ScreeningBlock
                  key={screening.screening_id}
                  style={{
                    top: `${startPercent}%`,
                    height: `${height}%`,
                  }}
                >
                  <FilmTitle>{screening.film_title}</FilmTitle>
                  <ScreeningInfo>
                    <span>
                      {formatScreeningTime(screeningStart, screeningEnd)}
                    </span>
                    <span>{screening.venue_name}</span>
                  </ScreeningInfo>
                </ScreeningBlock>
              );
            })}
          </DayColumn>
        );
      })}
    </CalendarGrid>
  );
};

export default CalendarView;
