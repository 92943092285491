import React from "react";
import {
  StyledContainer,
  HeroSection,
  HeroText,
  HeroImage,
  FeatureRow,
  FeatureCol,
  CTASection,
  StyledButton,
  FeatureHeader,
  FeatureImage,
  FeatureImageWrapper,
  TestimonialSection,
  TestimonialBlockquote,
  TestimonialImage,
  TestimonialParagraph,
  HeroImageContainer,
  HeroContentContainer,
  FeatureScrollContainer,
  HeroCTAButton,
  FeatureText,
  CTAContent,
  CTAButtons,
  CTAContentContainer,
} from "./Home.styled";
import AnnouncementBar from '../common/AnnouncementBar';

function Home() {
  return (
    <>
      <AnnouncementBar />
      {/* Hero Section */}
      <HeroSection>
        <HeroContentContainer>
          <HeroText>
            <h1>Generate the perfect festival schedule.</h1>
            <p>
              Explore festival lineups, pick your favorite events, and quickly
              generate the perfect schedule with our smart, overlap-free tool,
              specially design to help you have the best fest.
            </p>
            <HeroCTAButton to="/festivals">Get Started</HeroCTAButton>
          </HeroText>
          <HeroImageContainer>
            <HeroImage
              src="https://festive-qgp5wfc2j.s3.amazonaws.com/festive/images/home/festiv_hero_image_v003.jpeg"
              alt="Festiv Scheduler"
            />
          </HeroImageContainer>
        </HeroContentContainer>
      </HeroSection>

      <TestimonialSection>
        <TestimonialBlockquote>
          Save hours of time when building your next festival attendance
          schedule!
        </TestimonialBlockquote>
        {/* <TestimonialImage
          src="https://randomuser.me/api/portraits/men/66.jpg"
          alt="John Doe"
        />
        <TestimonialParagraph>
          Maria Lopez, VP of Design at Meshery
        </TestimonialParagraph> */}
      </TestimonialSection>

      {/* Features Section */}
      <StyledContainer>
        <FeatureRow>
          <FeatureHeader>
            <h2>Hassle-Free Scheduling</h2>
            <p>
              Maximize your festival experience with our streamlined scheduling
              tool, specially-designed to align with your priorities,
              preferences, and availability.
            </p>
          </FeatureHeader>
          <FeatureScrollContainer>
            <FeatureCol>
              <FeatureImageWrapper>
                <FeatureImage
                  src="https://images.unsplash.com/photo-1533174072545-7a4b6ad7a6c3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjZ8fGZpbG0lMjBmZXN0aXZhbHxlbnwwfHwwfHx8MA%3D%3D"
                  alt="Feature 1"
                />
              </FeatureImageWrapper>
              <FeatureText>
                Explore a simplified view of each festival and assign Priority
                Levels to the events you're most eager to attend.
              </FeatureText>
            </FeatureCol>
            <FeatureCol>
              <FeatureImageWrapper>
                <FeatureImage
                  src="https://images.unsplash.com/photo-1574267432553-4b4628081c31?q=80&w=1631&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt="Feature 2"
                />
              </FeatureImageWrapper>
              <FeatureText>
                Have dinner plans one night of the festival? Set up your Busy
                Times and we'll schedule around them.
              </FeatureText>
            </FeatureCol>
            <FeatureCol>
              <FeatureImageWrapper>
                <FeatureImage
                  src="https://images.unsplash.com/photo-1529942523273-74d30bfb6989?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt="Feature 3"
                />
              </FeatureImageWrapper>
              <FeatureText>
                Choose a default Buffer Time you're comfortable with so you have
                enough time between events.
              </FeatureText>
            </FeatureCol>{" "}
          </FeatureScrollContainer>
        </FeatureRow>
      </StyledContainer>

      {/* CTA Section */}
      <CTASection>
        <CTAContentContainer>
          <CTAContent>
            <h2>Ready to get started?</h2>
            <p>Sign Up Now or Contact Us!</p>
          </CTAContent>
          <CTAButtons>
            <StyledButton to="/festivals">Try It Out</StyledButton>
            <StyledButton
              className="outline"
              to="mailto:festivals@hyperbole.tv?subject=Festival%20Scheduler"
            >
              Contact Us
            </StyledButton>
          </CTAButtons>
        </CTAContentContainer>
      </CTASection>
    </>
  );
}

export default Home;
