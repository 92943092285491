import styled from "styled-components";

export const SchedulePreferencesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  position: sticky;
  top: 75px;
  z-index: 975;
  padding: 0rem 2rem;
  height: 75px;

  @media (max-width: 768px) {
    overflow-x: auto; // Enable horizontal scrolling
    white-space: nowrap; // Prevents wrapping of child elements
    padding: 0rem 1rem; // Adjust padding for smaller screens
    -webkit-overflow-scrolling: touch; // Smooth scrolling on touch devices
    gap: 15px;
    top: 155px;
  }
`;

export const TitleContainer = styled.div`
  //placeholder

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly; /* Evenly space items */
  
  gap: 0.5rem; /* Adjust spacing between items */

  select,
  button {
    margin: 0;
    color: #fff; /* Default for other labels, not dropdown */
  }

  button {
    background-color: #fff;
    color: #000;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: #e8e8e8;
      color: #000;
    }
  }

  select {
    background-color: #333;
    color: #fff;
    border: 1px solid #444;
    padding: 0.3rem 0.5rem;
    border-radius: 4px;
  }

  input[type="checkbox"] {
    accent-color: #fff;
  }

  .react-select__option {
    label {
      color: black !important; /* Dropdown options text color */
    }
  }

  @media (max-width: 768px) {
    gap: 15px;

    label,
    select {
      margin-left: 0;
    }
  }
`;


export const StyledLabel = styled.label`
  color: #fff;
  font-size: 1rem;
  display: inline-flex; /* Align inline elements consistently */
  align-items: center;
  font-weight: bold;
  margin-left: 1.5rem;

  input[type="checkbox"] {
    margin-right: 0.5rem;
  }
`;

export const StyledTitle = styled.div`
color: #fff;
  font-size: 1rem;
  display: inline-flex; /* Align inline elements consistently */
  align-items: left;
  font-weight: 700;
`;

