import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import moment from "moment-timezone";
import UserFilms from "../user/UserFilms";
import { useAuth } from "../../contexts/AuthContext";
import {
  FilmDetailsContainer,
  FilmImage,
  ScreeningsList,
  FilmTitle,
  ScreeningsContainer,
  LeftColumn,
  RightColumn,
  ContentWrapper,
  CalendarWrapper,
} from "./FilmDetails.styled";
import Loader from "../common/Loader";
import CalendarView from "../calendar/CalendarView";
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { NavigationArrow } from "./FilmDetails.styled";

function FilmDetails() {
  const { filmId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, token } = useAuth();

  const [film, setFilm] = useState(null);
  const [screenings, setScreenings] = useState([]);
  const [priority, setPriority] = useState(0);
  const [films, setFilms] = useState(location.state?.films || []);
  const [loadingFilms, setLoadingFilms] = useState(films.length === 0);

  
  const locationParts = location.pathname.split("/");
  const festivalIndex = locationParts.indexOf("festivals");
  const festivalId = festivalIndex !== -1 ? locationParts[festivalIndex + 1] : null;

  
  useEffect(() => {
    async function fetchFilmDetails() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/films/${filmId}`
        );
        const data = await response.json();
        setFilm(data); 
      } catch (error) {
        console.error("Error fetching film details:", error);
      }
    }
    fetchFilmDetails();
  }, [filmId]);

  
  useEffect(() => {
    async function fetchFilmScreenings() {
      try {
        const screeningsResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/screenings`
        );
        const allScreenings = await screeningsResponse.json();
        const filmScreenings = allScreenings.filter(
          (screening) => screening.film_id === Number(filmId)
        );

        const venuesResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/venues`
        );
        const venues = await venuesResponse.json();

        const screeningsWithVenues = filmScreenings.map((screening) => {
          const venue = venues.find((v) => v.venue_id === screening.venue_id);
          return {
            ...screening,
            venueName: venue ? venue.name : "Unknown Venue",
          };
        });

        setScreenings(screeningsWithVenues.sort(
          (a, b) => new Date(a.start_time) - new Date(b.start_time)
        ));
      } catch (error) {
        console.error("Error fetching film screenings:", error);
      }
    }
    fetchFilmScreenings();
  }, [filmId]);

  
  useEffect(() => {
    if (films.length === 0 && festivalId) {
      setLoadingFilms(true);
      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/films/festival/${festivalId}`)
        .then((res) => res.json())
        .then((data) => {
          setFilms(data);
          setLoadingFilms(false);
        })
        .catch((error) => {
          console.error("Error fetching festival films:", error);
          setLoadingFilms(false);
        });
    }
  }, [festivalId, films.length]);

  
  useEffect(() => {
    async function fetchUserFilmPriority() {
      if (!currentUser || !token) return;
  
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/userfilms/${filmId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
  
        if (response.ok) {
          const data = await response.json();
          setPriority(data.priority || 0);
        } else {
          console.warn("Could not fetch user film priority");
          setPriority(0);
        }
      } catch (error) {
        console.error("Error fetching user film priority:", error);
        setPriority(0);
      }
    }
    fetchUserFilmPriority();
  }, [filmId, currentUser, token]);
  
  
  const currentIndex = films.findIndex((f) => f.film_id === Number(filmId));
  const prevFilm = currentIndex > 0 ? films[currentIndex - 1] : null;
  const nextFilm = currentIndex < films.length - 1 ? films[currentIndex + 1] : null;

  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft" && prevFilm) {
        navigate(`/festivals/${festivalId}/films/${prevFilm.film_id}`, { state: { films } });
      } else if (event.key === "ArrowRight" && nextFilm) {
        navigate(`/festivals/${festivalId}/films/${nextFilm.film_id}`, { state: { films } });
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [prevFilm, nextFilm, navigate, festivalId, films]);

  const handleNavigation = (direction) => {
    const targetFilm = direction === 'prev' ? prevFilm : nextFilm;
    if (targetFilm) {
      navigate(`/festivals/${festivalId}/films/${targetFilm.film_id}`, { state: { films } });
    }
  };

  
  if (loadingFilms) {
    return <Loader />;
  }
  
  if (!film) {
    return <p>Could Not Find Film Details</p>;
  }

  return (
    <>
      {prevFilm && (
        <NavigationArrow
          className="left"
          onClick={() => handleNavigation("prev")}
          aria-label="Previous film"
        >
          <ChevronLeft fontSize="large" />
        </NavigationArrow>
      )}
      <FilmDetailsContainer>
        <ContentWrapper>
          <LeftColumn>
            <FilmImage src={film.image} alt={film.title} />
            {currentUser && (
              <UserFilms
                filmId={Number(filmId)}
                title={film.title}
                priority={priority}
                onPriorityChange={(filmId, newPriority) =>
                  setPriority(newPriority)
                }
              />
            )}
          </LeftColumn>

          <RightColumn>
            <FilmTitle>{film.title}</FilmTitle>
            <p>{film.description}</p>
            <p>
              <strong>Director:</strong> {film.director}
            </p>
            <p>
              <strong>Genre:</strong>{" "}
              {film.genres && film.genres.length > 0
                ? film.genres.map((genre) => genre.genre_name).join(", ")
                : "Unknown"}
            </p>
          </RightColumn>
        </ContentWrapper>

        <ScreeningsContainer>
          <h2>
            {screenings.length} Screening{screenings.length !== 1 ? "s" : ""}{" "}
            for {film.title}
          </h2>
          <ScreeningsList>
            {screenings.map((screening) => (
              <li key={screening.screening_id}>
                {moment(screening.start_time)
                  .tz(screening.timezone)
                  .format("M/D/YYYY, h:mm A")}
                {" @ "}
                {screening.venueName}
              </li>
            ))}
          </ScreeningsList>
        </ScreeningsContainer>
        {/* <CalendarWrapper> */}
          <CalendarView mode="per-day" filmId={filmId} />
        {/* </CalendarWrapper> */}
      </FilmDetailsContainer>
      {nextFilm && (
        <NavigationArrow
          className="right"
          onClick={() => handleNavigation("next")}
          aria-label="Next film"
        >
          <ChevronRight fontSize="large" />
        </NavigationArrow>
      )}
    </>
  );
}

export default FilmDetails;
