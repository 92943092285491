import { React, useRef, memo } from "react";
import { Link } from "react-router-dom";
import {
  FilmListItemContainer,
  PriorityAndImageContainer,
  FilmDetailsContainer,
  ScreeningsContainer,
  FilmImage,
  FilmTitle,
  FilmParagraph,
  ScreeningsParagraph,
  ViewMoreButton,
  UserFilmsContainer,
  ButtonText,
} from "./FilmListItem.styled";
import PropTypes from "prop-types";
import UserFilms from "../user/UserFilms";

const FilmListItem = memo(
  ({ film, userId, onPriorityChange, priority }) => {
    const {
      film_id: filmId,
      title,
      director,
      genres = [],
      image,
      screenings = [],
    } = film;

    return (
      <FilmListItemContainer>
        <PriorityAndImageContainer>
          <Link to={`/festivals/${film.festival_id}/films/${film.film_id}`}>
            <FilmImage src={image} alt={title} />
          </Link>
        </PriorityAndImageContainer>

        <FilmDetailsContainer>
          <FilmTitle>{title}</FilmTitle>
          <FilmParagraph>Director: {director}</FilmParagraph>
          <FilmParagraph>
            Genre: {genres.map((genre) => genre.genre_name).join(", ")}
          </FilmParagraph>
        </FilmDetailsContainer>

        <UserFilmsContainer>
          <UserFilms
            filmId={Number(filmId)}
            userId={userId}
            title={title}
            priority={priority}
            onPriorityChange={onPriorityChange}
          />
        </UserFilmsContainer>

        <ScreeningsContainer>
          <ScreeningsParagraph>
            {screenings.length} Screenings
          </ScreeningsParagraph>
          <ViewMoreButton to={`/festivals/${film.festival_id}/films/${film.film_id}`}>
            <ButtonText>View More</ButtonText>
          </ViewMoreButton>
        </ScreeningsContainer>
      </FilmListItemContainer>
    );
  },
  (prevProps, nextProps) => {
    // Only re-render if these props change
    return (
      prevProps.film.film_id === nextProps.film.film_id &&
      prevProps.priority === nextProps.priority &&
      prevProps.userId === nextProps.userId
    );
  }
);

export default FilmListItem;