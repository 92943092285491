import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import UserSchedule from './UserSchedule'; // Reuse your existing UserSchedule component
import { FestivalDetailContainer, } from '../festival/FestivalDetail.styled';
import Loader from '../common/Loader';


const PublicSchedule = () => {
  const { token } = useParams();
  const [scheduleData, setScheduleData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/schedule/id/${token}`);
        setScheduleData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching public schedule:', error);
        setIsLoading(false);
        // Handle error (e.g., show error message)
      }
    };

    fetchSchedule();
  }, [token]);

  if (isLoading) {
    return <Loader />;
  }

  if (!scheduleData) {
    return <div>Unable to fetch schedule.</div>; // Handle no data state
  }

  return (
    <FestivalDetailContainer>
    <UserSchedule 
      organizedSchedule={scheduleData.schedule}
      attendingFilms={scheduleData.attendingFilms}
      priorityStats={scheduleData.priorityStats}
      festivalTimezone={scheduleData.festivalTimezone}
      scheduleHash={scheduleData.scheduleHash}
      lastUpdated={scheduleData.lastUpdated}
      festivalName={scheduleData.festivalName}
      isPublicView={true}
    />
    </FestivalDetailContainer>
  );
};

export default PublicSchedule;