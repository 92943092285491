import styled from "styled-components";
import { Link } from "react-router-dom";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

export const UserFilmsDiv = styled.div`
  width: 100%;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

export const StyledBookmarkIcon = styled(BookmarkIcon)`
  color: inherit; // Ensures icon color matches the parent component
  font-size: 1.25rem !important; // Adjust the size as needed
`;

export const StyledBookmarkBorderIcon = styled(BookmarkBorderIcon)`
  color: inherit;
  font-size: 1.25rem !important;
`;

export const StyledStarRoundedIcon = styled(StarRoundedIcon)`
  color: inherit;
  font-size: 1.125rem;
`;

export const StyledStarBorderRoundedIcon = styled(StarBorderRoundedIcon)`
  color: inherit;
  font-size: 1.125rem;
`;

export const PriorityButton = styled.button`
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  width: 9rem;
  height: 3rem;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  background-color: transparent;
  color: #000;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 1.125rem;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  /* margin: 0 0.25rem; */

  background-color: ${(props) =>
    props.selected
      ? props.$buttonType === "maybe"
        ? "#83DAFA"
        : "#f9da1c"
      : "rgba(0, 0, 0, 0.1)"};
  color: ${(props) => (props.selected ? "#000" : "#000")};
  /* border: ${(props) => (props.selected ? "none" : "2px solid #000")}; */
  border: none;

  // Hover state
  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? "rgba(0, 0, 0, 0.1)"
        : props.selected
        ? "#000"
        : "#7F7F7F"};
    color: ${(props) => (props.disabled ? "#000" : "#fff")};

    // Styles for icons on hover
    ${StyledBookmarkIcon}, ${StyledBookmarkBorderIcon}, ${StyledStarRoundedIcon}, ${StyledStarBorderRoundedIcon} {
      color: ${(props) =>
        props.disabled
          ? "inherit"
          : props.$buttonType === "maybe"
          ? "#83DAFA"
          : "#f9da1c"};
    }
  }

  // Active state
  &:active {
    background-color: ${(props) =>
      props.disabled ? "rgba(0, 0, 0, 0.1)" : "#000"};
  }

  // Disabled state
  &:disabled {
    opacity: 0.5; // make the button more transparent
    cursor: not-allowed; // indicate that the button is not clickable
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    width: calc(50% - 5px);
    margin: 0;
  }
`;

export const Icon = styled.span`
  font-family: "Material Symbols Rounded", sans-serif;
  font-size: 1.125rem; // Adjust as needed
`;

export const QuestionText = styled.div`
  text-align: center;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-family: ${(props) => props.theme.fonts.primary};

  @media (max-width: 768px) {
    padding-top: 0.5rem;
    font-size: 0.9rem;
    text-align: left;
  }
`;

export const SpaceText = styled.div`
  text-align: center;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledLoginLink = styled(Link)`
  text-decoration: none; // Removes the underline
  font-weight: bold; // Makes the text slightly bolder
  color: inherit; // Inherits the color from the parent component
  &:hover {
    text-decoration: underline; // Underline on hover for better user experience
  }
`;
