import styled from "styled-components";

export const FilmDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  padding: 20px;
  margin: 20px auto;
  max-width: 1200px;
  width: 90%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 2rem;
    /* margin-bottom: 30px; */
  }
`;


export const LeftColumn = styled.div`
  flex-shrink: 0;
  width: 100%;
  
  @media (min-width: 768px) {
    width: 40%;
  }
`;

export const RightColumn = styled.div`
  width: 100%;
  padding-top: 1rem;
  
  @media (min-width: 768px) {
    flex: 1;
    /* padding-top: 0; */
  }
`;

export const FilmImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const FilmTitle = styled.h1`
  font-weight: 900;
  margin-top: 0;
  
  /* @media (max-width: 767px) {
    text-align: center;
  } */
`;

export const ScreeningsContainer = styled.div`
  width: 100%;
  /* padding-top: 20px; */
  border-top: 1px solid #eee;
  
  @media (min-width: 768px) {
    flex-basis: 100%;
  }
`;

export const ScreeningsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;

  li {
    padding: 12px 16px;
    background: #f8f8f8;
    border-radius: 5px;
    transition: background-color 0.2s;

    &:hover {
      background: #f0f0f0;
    }
  }
`;

export const NavigationArrow = styled.button`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.left {
    left: 20px;
  }

  &.right {
    right: 20px;
  }

  @media (max-width: 768px) {
    padding: 10px;
    
    &.left {
      left: 10px;
    }
    
    &.right {
      right: 10px;
    }
  }
`;

export const CalendarWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-top: 2rem;
`;