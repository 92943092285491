import styled from "styled-components";

export const CalendarContainer = styled.div`
  max-width: 100%;
  /* background-color: #eaeaea; */
  border-radius: 12px;
  /* padding: 15px 25px 25px 25px; */

  @media (max-width: 768px) {
    margin-top: 0;
    border-radius: 0;
    padding: 15px;
  }
`;

export const NavigationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const NavButton = styled.button`
  background: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background: #f0f0f0;
  }
`;

export const CurrentDate = styled.h2`
  margin: 0;
  font-weight: 900;
`;

export const VenuesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

export const VenueSection = styled.div`
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

export const VenueName = styled.h3`
  margin: 0 0 20px 0;
  padding-bottom: 10px;
  border-bottom: 2px solid #e8e8e8;
  font-weight: 700;
`;

export const ScreeningsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ScreeningCard = styled.div`
  padding: 15px;
  background-color: #f8f8f8;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const FilmTitle = styled.div`
  font-weight: 700;
  font-size: 0.7rem;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ScreeningTime = styled.div`
  font-size: 0.9rem;
  color: #666;
`;

export const NoScreenings = styled.div`
  color: #666;
  font-style: italic;
  font-size: 0.9rem;
  padding: 10px 0;
`;

export const ViewToggle = styled.button`
  background: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-left: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    background: #555;
  }
`;

export const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: 60px repeat(${(props) => props.numDays}, minmax(120px, 1fr));
  grid-template-rows: 50px 1fr;
  background: #ffffff;
  border: 1px solid #eaeaea;
  width: 100%;
  height: 650px;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  border-radius: 8px;
`;

export const DayLabel = styled.div`
  padding: 5px;
  background: #f7f7f7;
  text-align: center;
  font-weight: bold;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .day-name {
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 700;
  }

  .date {
    font-size: 1.2rem;
    font-weight: 900;
  }
`;

export const DayColumn = styled.div`
  position: relative;
  height: 100%;
  border-right: 1px solid #eaeaea;
  background-image: linear-gradient(to bottom, #eaeaea 1px, transparent 1px);
  background-size: 100% calc(100% / 18);

  &:last-child {
    border-right: none;
  }
`;

export const TimeMarker = styled.div`
  position: absolute;
  width: 100%;
  padding: 5px 10px 5px 0; // Adjust padding to right-align text
  text-align: right;
  font-size: 0.7rem;
  font-weight: 700;
  transform: translateY(-6px); // Fine-tune vertical alignment
  z-index: 2;
  box-sizing: border-box;
`;

export const TimeColumn = styled.div`
  position: absolute;
  left: 0;
  top: 50px;
  height: calc(100% - 50px);
  width: 60px;
  background: #f7f7f7;
  z-index: 1;
  border-right: 1px solid #eaeaea;
`;

export const ScreeningBlock = styled.div`
  position: absolute;
  left: 5px;
  right: 5px;
  background: #007bff;
  color: white;
  padding: 4px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
  text-align: left;
  box-sizing: border-box;
  z-index: 2;
  min-height: 40px;
  overflow: hidden;

  &:hover {
    background: #0056b3;
  }
`;

export const ScreeningInfo = styled.div`
  font-size: 0.7rem;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  line-height: 1.2;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span:last-child {
    opacity: 0.8;
  }
`;