import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

const expandDown = keyframes`
  from {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
  to {
    max-height: 60px;
    padding: 12px 20px;
    opacity: 1;
  }
`;

const AnnouncementContainer = styled.div`
  background-color: #8BC34A;
  color: #000;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
  overflow: hidden;
  
  animation: ${expandDown} 0.5s ease forwards;
  animation-delay: 500ms;
  max-height: 0;
  padding: 0;
  opacity: 0;

  &:hover {
    background-color: #7CB342;
  }

  a {
    color: inherit;
    text-decoration: none;
    display: block;
    width: 100%;
  }
`;

const AnnouncementBar = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  if (!isVisible) return null;

  return (
    <AnnouncementContainer>
      <Link to="/festivals/5">
        SXSW 2025 Festival Available Now
      </Link>
    </AnnouncementContainer>
  );
};

export default AnnouncementBar;